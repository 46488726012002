<template>
    <div class="dashboard-builder-item flex-container" :class="{'item-expanded' : !isCollapsed}" :style="{'height': height}">
        <div class="unit-details flex-container">
            <img :src="expandIcon" @click="expandContent">
            <span class="legend">{{$t('unit')}} {{component.number}}</span>
            <span>{{component.name[locale]}}</span>
            <img v-tooltip="'Go to Unit Builder'" :src="chevronIcon" @click="goToUnitBuilder">
            <img :src="dragHandlerIcon" v-if="isCollapsed">
            <button class="btn secondary" v-else @click="showModal = true">{{$t('delete')}} {{$t('unit')}}</button>
        </div>
        <unit-content v-if="!isCollapsed" :component="component"/>
        <modal v-if="showModal" :click-handler="deleteUnit"
            :text-button="$t('delete')"  @close="showModal = false">
            <div class="delete-message row">
                <span>{{$t('deleteConfirmationMessage',{'0': $t('unit')})}}</span>
            </div>
        </modal>
    </div>
</template>
<script>
import Modal from '@/common/Modal.vue'
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import UnitDashboardBuilderComponentContent from './UnitDashboardBuilderComponentContent.vue'
export default {
    name: 'UnitDashboardBuilderComponent', 
    mixins:[BackOfficeComponentMixin],  
    components:{
        'unit-content':UnitDashboardBuilderComponentContent, 
        Modal
    }, 
    data:function(){
        return{
            showModal: false
        }
    },
    computed:{
        isCollapsed(){
            return this.$store.state.currentBackOfficeComponent === this.component 
                ? false : true
        }, 
        dragHandlerIcon(){
            return require('@/assets/icons/ic_drag_handler.svg')
        },
        chevronIcon(){
            return require('@/assets/icons/chevron_right.svg')
        },
        expandIcon(){
            if(this.isCollapsed)
                return require('@/assets/icons/expand_more.svg')
            else
                return require('@/assets/icons/expand_less.svg')
        }, 
        height(){
            return this.isCollapsed
                ? '56px' :
                `${230
                + (this.component.biographies.length * 40) 
                +(this.component.lessons.length * 40) 
                + (this.component.hasTimeLine ? 40 : 0) 
                + ((this.component.lessons.length -1) * 40)
                + (this.assessments.length * 40)}px`
        }, 
        assessments(){
            return this.component.lessons.filter(e => e.hasAssessment)
        },
    }, 
    methods:{
        expandContent(){
            this.isCollapsed
                ? this.updateCurrentComponent()
                : this.$store.commit('updateCurrentBackOfficeComponent', undefined)
        }, 
        goToUnitBuilder(){
            this.$router.push({
                name: 'UnitBuilder', 
                params:{
                    unitId: this.component.id
                }
            })
        },
        deleteUnit(){
            this.showModal = false;
            this.$store.dispatch('removeUnitDashboardComponent', this.component)
            this.$store.commit('updateCurrentBackOfficeComponent', undefined)
        }
    }
}
</script>
<style lang="less" scoped>
 .dashboard-builder-item{
    margin: 8px auto;
	height: 56px;
	width: 704px;
	border-radius: 4px;
    background-color: #F6F7FA;
    box-shadow: var(--primary-shadow);
    transition: all 0.3s;
    .unit-details{
        align-items: center;
        width: 100%;
        height: 56px;
        >img{
            height: 16px;
            width: 16px;
            cursor: pointer;
        }
        img:nth-child(1){
            margin-left: 24px;
        }
        span:nth-child(2){
            margin-left: 32px;
            // min-width: 85px;
            
        }
        span:nth-child(3){
            height: 24px;
            margin-left: 16px;
            width: auto;
            max-width: 50%;
            color: #3A4DFD;
            font-family: Roboto;
            font-size: 16px;
            font-style: italic;
            font-weight: 500;
            letter-spacing: 0.15px;
            line-height: 24px;
        }
        img:nth-child(4){
            margin-left: 16px;
        }
        img:nth-child(5){
            margin-left: auto;
            margin-right: 16px;
            cursor: -webkit-grab;
        }
    }
 }

 .item-expanded{
	height: auto;
	border-radius: 4px;
	background-color: #FFFFFF;
    box-shadow: var(--secondary-shadow);
    flex-direction: column;
    padding-bottom: 22px;
    
 }

 .btn{
     margin-right: 16px;
     margin-left: auto;
 }

</style>


